import { Component, OnInit, Injector, ViewChild, ElementRef, HostListener } from "@angular/core";
import { AppComponentBase } from "../../../../../shared/common/app-component-base";
import { ActivatedRoute } from "@angular/router";
import { Chart, ChartData } from "chart.js";
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { of } from "rxjs/internal/observable/of";
import { catchError, takeUntil, shareReplay, retry } from "rxjs/operators";
import { SidelineServiceProxy, FundraisersServiceProxy } from '../../../../../shared/service-proxies/service-proxies';
import { Table } from 'primeng/table';
import { Subject } from "rxjs/internal/Subject";
import Fuse from 'fuse.js';
import { SelectItem } from "primeng/api";
import { SidelineSignalrService } from "./sideline-signalr.service";

@Component({
    selector: 'app-search',
    styleUrls: ['./sideline-report.component.less'],
    templateUrl: './sideline-report.component.html',
    animations: [appModuleAnimation()]
})
export class SidelineReportComponent extends AppComponentBase implements OnInit {
    @ViewChild('dataTable', { static: true }) dataTable: Table;

    fundraiserId: number;
    originalParticipants: any = [];
    fundraiser: any;
    participants: any = [];
    participantTeams: any = [];
    fundraiserProgress: any;
    totalCallsAndTexts: any = {
        totalTexts: -1,
        totalCalls: -1
    };
    totalContacts: number = -1;
    totalTexts: number = -1;
    totalCalls: number = -1;
    contactGoal: number = 0;
    contactGoalPercentage: string;
    textsGoalPercentage: string;
    callsGoalPercentage: string;
    progressGoalPercentage: string;
    teams: any = [];
    filterText = '';
    teamName: string = '';

    selectedTeams: any = [];

    selectedParticipantTeams: SelectItem[] = [];

    teamResultsChart: Chart;
    isSidelineEnabled: boolean;
    isFundraiserDollarMetric: boolean = true;
    isLoading: boolean;

    fuse: Fuse<any>;

    private readonly onDestroy = new Subject();
    private isSubscribedToParticipantResults: boolean = false;
    private isSubscribedToProgressResults: boolean = false;
    private isSubscribedToTeamResultsResults: boolean = false;
    private isSubscribedToTotalContacts: boolean = false;

    constructor(
        injector: Injector,
        private _activatedRoute: ActivatedRoute,
        private _sidelineServiceProxy: SidelineServiceProxy,
        private _fundraisersServiceProxy: FundraisersServiceProxy,
        private _sidelineSignalrService: SidelineSignalrService
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this._activatedRoute.params.pipe(takeUntil(this.onDestroy)).subscribe(async params => {
            if (params?.slug) {
                this._sidelineServiceProxy.getIsSidelineEnabledForFundraiserSlug(params.slug)
                    .pipe(
                        takeUntil(this.onDestroy),
                        shareReplay(1)
                ).subscribe(async isSidelineEnabled => {
                    this.isSidelineEnabled = isSidelineEnabled;

                    if (this.isSidelineEnabled) {
                        this._fundraisersServiceProxy.getFundraiserUserInfo(undefined, params.slug, undefined)
                            .pipe(
                                takeUntil(this.onDestroy),
                                shareReplay(1)
                            )
                            .subscribe(async result => {
                            if (result) {
                                this.fundraiser = result;
                                this.fundraiserId = result.fundraiserId;

                                await this._sidelineSignalrService.initializeConnectionAsync(result.fundraiserId.toString());

                                this.getTotalContacts();
                                this.getTeamResults();
                                this.getProgress();
                                this.getParticipantResults();

                                // TODO: Move these into components after we merge the contacts/sms/text
                                // sprocs. Then we can use OnPush change detection to reduce renders, and
                                // reduce the number of times we call these functions.
                                this._sidelineSignalrService.$connectionStatusChanged.pipe(takeUntil(this.onDestroy))
                                .subscribe({
                                    next: (isConnected) => {
                                        if (!isConnected) {
                                            return;
                                        }

                                        // Participants Results Subscription
                                        if (!this.isSubscribedToParticipantResults) {
                                            this.isSubscribedToParticipantResults = this._sidelineSignalrService.addParticipantResultsChangedListener();
                                            this._sidelineSignalrService.$participantResultsChanged.pipe(takeUntil(this.onDestroy))
                                                .subscribe({
                                                    next: this.handleParticipantResultsChanged.bind(this),
                                                    error: (err) => abp.log.error('[Sideline] Unable to update participant results. Try again later.')
                                                });
                                        }

                                        // Progress Results Subscription
                                        if (!this.isSubscribedToProgressResults) {
                                            this.isSubscribedToProgressResults = this._sidelineSignalrService.addProgressResultsChangedListener();
                                            this._sidelineSignalrService.$progressResultsChanged.pipe(takeUntil(this.onDestroy))
                                                .subscribe({
                                                    next: this.handleProgressResultsChanged.bind(this),
                                                    error: (err) => abp.log.error('[Sideline] Unable to update fundraiser progress. Try again later.')
                                                });
                                        }

                                        // Team Results Subscription
                                        if (!this.isSubscribedToTeamResultsResults) {
                                            this.isSubscribedToTeamResultsResults = this._sidelineSignalrService.addTeamResultsChangedListener();
                                            this._sidelineSignalrService.$teamResultsChanged.pipe(takeUntil(this.onDestroy))
                                                .subscribe({
                                                    next: this.handleTeamResultsChanged.bind(this),
                                                    error: (err) => abp.log.error('[Sideline] Unable to update team results. Try again later.')
                                                });
                                        }

                                        // Total Contacts Subscription
                                        if (!this.isSubscribedToTotalContacts) {
                                            this.isSubscribedToTotalContacts = this._sidelineSignalrService.addTotalContactsChangedListener();
                                            this._sidelineSignalrService.$totalContactsChanged.pipe(takeUntil(this.onDestroy))
                                                .subscribe({
                                                    next: this.handleTotalContactsChanged.bind(this),
                                                    error: (err) => abp.log.error('[Sideline] Unable to update total contacts. Try again later.')
                                                });
                                        }
                                    },
                                    error: (err) => abp.log.error('[Sideline] Unable to update one or more reports. Try again later.')
                                });
                            }
                        });
                    }
                });
            }
        });
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    onSelectedTeamChange($event: { itemValue: any; }) {
        const index = this.selectedTeams.indexOf($event.itemValue);
        if (index > -1) {
            this.selectedTeams.splice(index, 1);
        } else {
            this.selectedTeams = [...this.selectedTeams, $event.itemValue];
        }

        this.onSearch();
    }

    onSearch() {
        if (this.filterText.trim() === '') {
            this.participants = [...this.originalParticipants];
        } else {
            this.participants = this.fuse.search(this.filterText).map(result => result.item);
        }

        if (this.selectedTeams.length > 0) {
            this.participants = this.participants.filter(obj => this.selectedTeams.includes(obj.teamId));
        }
    }

    getParticipantResults(readFromCache: boolean = true): void {
        this._sidelineServiceProxy.getSidelineParticipantResults(this.fundraiserId, readFromCache).pipe(
            takeUntil(this.onDestroy),
            shareReplay(1),
            retry(3)
        ).subscribe(results => {
            if (results) {
                this.participants = [...results];
                if (this.originalParticipants.length === 0) {
                    this.originalParticipants = [...results];

                    // "No Team" doesn't count as a team, so we only show it if there are other teams
                    if (this.participants.filter(x => x.teamName !== null).length > 0) {
                        this.participantTeams = Array.from(
                            new Map(this.participants.map((member: { teamId: any; teamName: any; }) => [member.teamId, { teamName: member?.teamName === null ? 'No Team' : member?.teamName, teamId: member?.teamId }])).values()
                        );

                        // sort teams by name
                        this.participantTeams.sort((a, b) => a.teamName.localeCompare(b.teamName));
                    }
                }
                this.fuse = new Fuse(this.participants, {
                    keys: ['name'],
                    isCaseSensitive: false,
                    includeScore: false,
                    shouldSort: true,
                    includeMatches: false,
                    findAllMatches: false,
                    minMatchCharLength: 1,
                    location: 0,
                    threshold: 0.1,
                    distance: 100,
                    useExtendedSearch: false,
                    ignoreLocation: false,
                    ignoreFieldNorm: false,
	                fieldNormWeight: 1,
                });
            }
        },
        () => {
            abp.log.debug('[Sideline] Unable to update participant results. Try again later.');
        });
    }

    getProgress(readFromCache: boolean = true): void {
        this._sidelineServiceProxy.getSidelineFundraiserProgress(this.fundraiserId, readFromCache).pipe(
            takeUntil(this.onDestroy),
            shareReplay(1),
            retry(3)
        ).subscribe(results => {
            if (results) {
                this.fundraiserProgress = results;

                if (this.fundraiserProgress?.fundraiserMetric?.toLowerCase() != 'dollars') {
                    this.isFundraiserDollarMetric = false;
                }

                const earned = this.isFundraiserDollarMetric ? this.fundraiserProgress.totalDonations + this.fundraiserProgress.totalPaid : this.fundraiserProgress.totalUnits;

                if (this.fundraiserProgress.teamGoal > 0) {
                    this.progressGoalPercentage = Math.floor((earned / this.fundraiserProgress.teamGoal) * 100).toFixed(0);

                    // if the percentage is 0, but the total is greater than 0, we want to show "< 1%" instead of 0
                    if (this.progressGoalPercentage == '0' && earned > 0) {
                        this.progressGoalPercentage = '< 1'; 
                    }
                }
                else {
                    this.progressGoalPercentage = '0';
                }
            }
        },
        () => {
            abp.log.debug('[Sideline] Unable to update fundraiser progress. Try again later.');
        });;
    }

    getTeamResults(readFromCache: boolean = true): void {
        this._activatedRoute.params.pipe(
            takeUntil(this.onDestroy),
            shareReplay(1),
            retry(3)
        ).subscribe(params => {
            if (params?.slug) {
                this._sidelineServiceProxy.getSidelineTeamResults(this.fundraiserId, readFromCache).pipe(
                    takeUntil(this.onDestroy),
                    catchError(_ => {
                        return of({ count: 0, min: 0, max: 0 } as ChartData);
                    }),
                    shareReplay(1)
                ).subscribe(teamResultsData => {
                    if (teamResultsData && teamResultsData.labels?.length > 0) {
                        this.teams = teamResultsData.labels;
                        this.teamResultsChart = new Chart('teamResultsChart', {
                            type: 'bar',
                            data: teamResultsData,
                            options: null
                        });
                    }
                });
            }
        },
        () => {
            abp.log.debug('[Sideline] Unable to update team results. Try again later.');
        });
    }

    getTotalContacts(readFromCache: boolean = true): void {
        this._sidelineServiceProxy.getSidelineContactsInfo(this.fundraiserId, readFromCache).pipe(
            takeUntil(this.onDestroy),
            shareReplay(1),
            retry(3)
        ).subscribe(results => {
            if (!results)
                return;
            
            this.totalContacts = results.totalContacts;
            this.contactGoal = results.totalContactsGoal;
            this.totalTexts = results.totalTexts;
            this.totalCalls = results.totalCalls;
            this.contactGoalPercentage = this.totalContacts > 0 ? (Math.floor(this.totalContacts / this.contactGoal * 100)).toFixed(0) : '0';

            // if the percentage is 0, but the total is greater than 0, we want to show "< 1%" instead of 0
            if (this.contactGoalPercentage == '0' && this.totalContacts > 0) {
                this.contactGoalPercentage = '< 1';
            }

            if (!this.textsGoalPercentage && this.totalCallsAndTexts) {
                this.updateTextCallsPercentages();
            }
        },
        () => {
            abp.log.debug('[Sideline] Unable to update total contacts. Try again later.');
        });
    }

    updateTextCallsPercentages() {
        this.textsGoalPercentage = this.totalTexts > 0 ? (Math.floor(this.totalTexts / this.totalContacts * 100)).toFixed(0) : '0';
        this.callsGoalPercentage = this.totalCalls > 0 ? (Math.floor(this.totalCalls / this.totalContacts * 100)).toFixed(0) : '0';

        if (this.textsGoalPercentage == '0' && this.totalTexts > 0) {
            this.textsGoalPercentage = '< 1';
        }
        if (this.callsGoalPercentage == '0' && this.totalCalls > 0) {
            this.callsGoalPercentage = '< 1';
        }
    }

    pageYoffset = 0;
    @HostListener('window:scroll', ['$event']) onScroll(event) {
        this.pageYoffset = window.scrollY;
    }

    private handleParticipantResultsChanged(readFromCache: boolean = true): void {
        this.getParticipantResults(true);
    }

    private handleProgressResultsChanged(readFromCache: boolean = true): void {
        this.getProgress(true);
    }

    private handleTeamResultsChanged(readFromCache: boolean = true): void {
        this.getTeamResults(true);
    }

    private handleTotalContactsChanged(readFromCache: boolean = true): void {
        this.getTotalContacts(true);
    }
}