import { Injectable, Injector, NgZone, Output, EventEmitter } from '@angular/core';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@node_modules/@microsoft/signalr/dist/esm';
import { Observable, Subject, throwError } from '@node_modules/rxjs';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppConsts } from '@shared/AppConsts';
import { catchError, shareReplay, takeUntil } from '@node_modules/rxjs/operators';

@Injectable()
export class SidelineSignalrService extends AppComponentBase {
    @Output() $connectionStatusChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() $participantResultsChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() $progressResultsChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() $teamResultsChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() $totalContactsChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    private _serverlessHub: HubConnection = undefined;
    private _http: HttpClient;
    private readonly onDestroy = new Subject();
    private visibilityChangeHandler: () => void;

    constructor(injector: Injector, public _zone: NgZone, http: HttpClient) {
        super(injector);

        this._http = http;
        this.visibilityChangeHandler = this.handleVisibilityChange.bind(this);
        document.addEventListener('visibilitychange', this.visibilityChangeHandler);
    }

    public ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
        document.removeEventListener('visibilitychange', this.visibilityChangeHandler);
    }

    public async initializeConnectionAsync(groupName: string = undefined): Promise<void> {
        this.getConnectionInfo().pipe(
            takeUntil(this.onDestroy),
            shareReplay(1))
            .subscribe(async (info) => {
                this._serverlessHub = new HubConnectionBuilder()
                    .withUrl(info.Url, { accessTokenFactory: () => info.AccessToken })
                    .withAutomaticReconnect()
                    .build();
        
                await this.startConnectionAsync().catch(error => abp.log.error('[Sideline] Error starting real-time communications.'));
                this.setupConnectionHandlers();
                
                if (groupName) {
                    await this.joinGroup(this._serverlessHub.connectionId, groupName);
                }
            },
            (error) => { 
                abp.log.error('[Sideline] Error retrieving real-time communication details. Could not establish connection.');
            }
        );
    }

    public addParticipantResultsChangedListener(): boolean {
        try {
            this._serverlessHub.on('SidelineReportParticipantsChanged', (message: any) => {
                this.$participantResultsChanged.emit(message);
            });
            return true;
        } catch (error) {
            return false;
        }
    }

    public addProgressResultsChangedListener(): boolean {
        try {
            this._serverlessHub.on('SidelineReportFundraiserProgressChanged', (message: any) => {
                this.$progressResultsChanged.emit(message);
            });
            return true;
        } catch (error) {
            return false;
        }
    }

    public addTeamResultsChangedListener(): boolean {
        try {
            this._serverlessHub.on('SidelineReportTeamResultsChanged', (message: any) => {
                this.$teamResultsChanged.emit(message);
            });
            return true;
        } catch (error) {
            return false;
        }
    }

    public addTotalContactsChangedListener(): boolean {
        try {
            this._serverlessHub.on('SidelineReportTotalContactsChanged', (message: any) => {
                this.$totalContactsChanged.emit(message);
            });
            return true;
        } catch (error) {
            return false;
        }
    }

    // TODO: Remove this once we have the cache fully setup in realtime. This is a timer based hack
    public addAllReportsChangedListener(): boolean {
        try {
            this._serverlessHub.on('SidelineReportAllChanged', () => {
                this.$totalContactsChanged.emit(false);
                this.$teamResultsChanged.emit(false);
                this.$progressResultsChanged.emit(false);
                this.$participantResultsChanged.emit(false);
            });
            return true;
        } catch (error) {
            return false;
        }
    }

    // Add this connnection to a group. For example a fundraiser. Required to recieve sideline
    // report SignalR messages.
    private async joinGroup(connectionId: string, groupName: string): Promise<void> {
        await this._serverlessHub.invoke('JoinGroup', connectionId, groupName)
            .catch(err => abp.log.debug('[Sideline] Error while establishing fundraiser group connection'));
    }

    private setupConnectionHandlers(): void {
        this._serverlessHub.onreconnecting(error => {
            this.$connectionStatusChanged.emit(this._serverlessHub.state === HubConnectionState.Connected);
        });

        this._serverlessHub.onreconnected(connectionId => {
            this.$connectionStatusChanged.emit(this._serverlessHub.state === HubConnectionState.Connected);
        });

        this._serverlessHub.onclose(async (error) => {
            await this.startConnectionAsync();
            this.$connectionStatusChanged.emit(this._serverlessHub.state === HubConnectionState.Connected);
        });
    }

    private async startConnectionAsync(): Promise<void> {
        if (this._serverlessHub.state === HubConnectionState.Connected)
            return;

        await this._serverlessHub.start()
            .then(() => { 
                this.$connectionStatusChanged.emit(this._serverlessHub.state === HubConnectionState.Connected);
            })
            .catch((error) => {
                abp.log.error('[Sideline] Unable to establish real-time communications.');
                this.$connectionStatusChanged.emit(this._serverlessHub.state === HubConnectionState.Connected);
            }).catch(error => abp.log.error('[Sideline] Unable to establish real-time communications.'));
    }

    private getConnectionInfo(): Observable<any> {
        const signalrurl: URL = new URL('api/negotiate', AppConsts.serverlessSignalrBaseUrl);
        const params = new URLSearchParams({ code: AppConsts.backgroundJobCode });
        signalrurl.search = params.toString();

        return this._http.post<any>(signalrurl.toString(), {})
          .pipe(
            catchError(this.handleNegotiationError)
          );
    }

    private handleNegotiationError(error: HttpErrorResponse) {
        const message: string = '[Sideline] An error occurred while negotigating connection';
        abp.log.error(message);
        return throwError(() => new Error(message));
    }

    // Detect when a user has come back to a page to rejoin signalr if needed
    private async handleVisibilityChange(): Promise<void> {
        if (document.visibilityState === 'visible') {
            // Check SignalR connection status and reconnect if necessary
            if (this._serverlessHub.state === HubConnectionState.Disconnected) {
                await this.startConnectionAsync();
            }
        }
    }
}
